@use "../utils/variables"as *;

.search {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px;

    .search-helper-desc {
        text-align: center;
    }

    .component-title {
        margin-bottom: 30px;

        @media (min-width: $lg) {
            margin-bottom: 50px;
        }
    }

    .error {
        color: $kdm-red--error;
        text-align: center;
        padding: 20px 0;
    }

    &__form {
        max-width: 615px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0 auto;

        input {
            width: 100%;
            margin-bottom: 30px;

            @media (min-width: $lg) {
                margin-bottom: 18px;
            }
        }
    }

    @media (min-width: $lg) {
        position: static;
        left: auto;
        top: auto;
        transform: translateY(0);
        padding: 0;
    }
}

.search-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 46px;

    .search-list {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 500px;
        width: 100%;

        label {
            margin-bottom: 26px;

            &:last-child {
                margin-bottom: 0;
            }

            @media (min-width: $lg) {
                margin-bottom: 34px;
            }
        }

        @media (min-width: $lg) {
            margin-bottom: 45px;
        }
    }

    .search-buttons {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;

        button {
            margin-bottom: 12px;

            &:first-child {
                margin-left: 0;
            }

            @media (min-width: $lg) {
                margin: 0 0 0 22px;
            }
        }

        @media (min-width: $lg) {
            flex-direction: row;
        }
    }

    @media (min-width: $lg) {
        padding-top: 40px;
    }
}