@use "../utils/variables"as *;

.radio-btn {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
      
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .checkmark {
            border-color: #0073DA;
        }
        &:checked ~ .city {
            color: #0073DA;
        }
        &:checked ~ .address {
            color: #0073DA;
        }

        &:checked ~ .checkmark {
            &:after {
                display: block;
                background-color: #0073DA;
            }
        }
    }
      
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: transparent;
        border-radius: 50%;
        border: 4px solid #D8D5D5;

        &:after {
            top: 3px;
            left: 3px;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background: white;
        }
    }
      
    .checkmark {
        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    .city {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }

    .address {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }
}

.input-field {
    border: 2px solid $kdm-blue;
    border-radius: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    padding: 19px 26px;
    color: $kdm-gray;
}