@use "../utils/variables" as *;

.planograms_container_wrapper {

    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    

    @media(min-width: 1200px) {
        flex-direction: column;
        overflow-x: initial;
        
    }

}

.planograms_container {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    margin: 50px 0 0;

    &:first-of-type {
        margin-left: -20px;

        @media(min-width: 1200px) {
            margin-left: 0;
        }    
    }

    @media(min-width: 1200px) {
        width: 100%;
    }
    


    &::-webkit-scrollbar {
        border-bottom: 5px solid #F4F4F4;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: $kdm-blue; 
        border-radius: 10px;
    }

    .planogram {
        $self: &;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        position: relative;
        margin: 0 20px 30px;
        
        @media(min-width: 1200px) {
            margin: 0 0 30px;
        }

       
        &__image {
            border: 4px solid rgba(216, 213, 213, .5);
            padding: 60px 12px;
            position: relative;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 334px;
            height: 278px;
            $self: &;
                
            @media(min-width: 1200px) {
                width: 241px;
                height: 241px;
            }

            img {
                max-height: 210px;
            }

            &--lg {
                border: 4px solid rgba(216, 213, 213, .5);
                padding: 36px 28px;
                position: relative;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 334px;
                height: 278px;
                
                @media(min-width: 1200px) {
                    width: 374px;
                    height: 312px;
                }

                img {
                    max-height: 210px;
                }
            }

            &--auto {
                
                
                position: relative;
                
                display: flex;
                align-items: center;
                justify-content: center;
                height: 278px;
                width: 494px;
                

                > img {
                    overflow: hidden;
                    border-radius: 8px;
                    display: flex;
                    height: 278px;
                    width: 494px;
                }

                @media(min-width: 1200px) {

                    height: 312px;
                    width: 555px;

                    > img {
                        display: flex;
                        height: 312px;
                        width: 555px;
                    }

                }

                button {
                    position: absolute;
                    left: 5%;
                    top: 50%;
                    transform: translateY(-50%);
                    user-select: none;
                    pointer-events: none;
                }

                
            }

            &--last {
                border: 4px solid rgba(216, 213, 213, .5);
                padding: 43px;
                position: relative;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 334px;
                height: 278px;

                @media(min-width: 1200px) {
                    width: 241px;
                    height: 241px;
                }
    
                img {
                    max-height: 210px;
                }
            }
        }

        
    
        .check-box {
            border: 4px solid $kdm-card-grey;
            background-color: $kdm-white;
            width: 57px;
            height: 57px;
            position: absolute;
            border-radius: 50%;
            top: -15px;
            right: -15px;
        }
        
        &__text {
            padding: 16px;
            text-align: center;
    
            p {
                font-size: 18px;
                line-height: 21px;
            }
        }
    }
}
