@use "../utils/variables"as *;

.text_container {
    margin: 0 auto 42px auto;
    max-width: 966px;
    text-align: center;
}

.progress_component {
    margin-bottom: 50px;

    .progress_bar {
        display: flex;
        justify-content: center;
        max-width: 800px;
        margin: 0 auto;
        position: relative;

        &::after {
            content: "";
            background-image: url("../../../public/assets/circle_dots.svg");
            position: absolute;
            z-index: -1;
            height: 2px;
            width: 70%;
            top: 28px;
        }
    
        .progress_circle {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 10px;
            text-align: center;
            flex: 1;
              
            > p {
                font-size: 16px;
                line-height: 19px;
                color: #323232;
            }
            
            .circle {
                $self: &;
                text-align: center;
                margin: 0 10px 20px 10px;
                font-size: 30px;
                font-weight: 900;
                line-height: 35px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: relative;

                &:nth-child(3) {
                    overflow: hidden;
                }
        
                p {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    
                }
        
                &--active {
                    border: 2px solid $kdm-blue;
                    background-color: $kdm-blue;
                    color: $kdm-white;
                }
        
                &--default {
                    border: 2px solid $kdm-blue;
                    border-radius: 50%;
                    background-color: $kdm-white;
                    color: $kdm-blue;
                }

                &--checked {
                    border: 2px solid $kdm-blue;
                    background-color: $kdm-blue;
                    content: url("../../../public/assets/icons/checkmark_progress.svg")
                }
            }
        }
    }
}




