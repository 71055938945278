@use "../utils/variables"as *;

.intro-page-header {
    text-align: center;
    padding: 26px 30px 0;
    display: flex;
    justify-content: center;

    img {
        width: 314px;
    }

    @media (min-width: $xl) {
        justify-content: flex-start;
        padding: 24px 24px;
    }
}

.intro-page {
    position: relative;
    padding: 50px 0 140px;

    h1 {
        text-align: center;
        margin-bottom: 24px;
    }

    &__inner {
        padding: 0 20px;
        width: 100%;
        margin: 0 auto;
        max-width: 700px;

        @media (min-width: $xl) {
            max-width: 960px;
        }
    }

    &__list {
        max-width: 600px;
        width: 100%;
        margin: 0 auto 38px;

        .list-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            
            span {
                color: $kdm-blue;
                font-size: 38px;
                line-height: 45px;
                font-weight: 700;
                margin-right: 8px;
            }
            
            p {
                font-weight: 400;
                color: #606585;
                font-size: 18px;
                line-height: 21px;
                padding-top: 10px;
                
                @media (min-width: $lg) {
                    font-size: 21px;
                    line-height: 25px;
                }
            }
        }
    }

    .button {
        margin-bottom: 24px;
        margin: 0 auto;
        display: block;

        @media (min-width: $lg) {
            padding: 20px 37px;
            font-size: 20px;
            line-height: 23px;
        }
    }

    &__mobile-img {
        width: 100%;   

        @media (min-width: $lg) {
            display: none;
        }
    }

    &__desktop {
        display: none;
        width: auto;
        position: absolute;
        bottom: 0;
        height: 100%;
        object-fit: cover;
        z-index: -1;

        &.left {
            left: -25%;

            @media (min-width: $xl) {
                left: -15%;
            }

            @media (min-width: $xxl) {
                left: -10%;
            }

            @media (min-width: $hd) {
                left: -5%;
            }
        }

        &.right {
            right: -20%;
            
            @media (min-width: $xl) {
                right: -15%;
            }

            @media (min-width: $xxl) {
                right: -10%;
            }

            @media (min-width: $hd) {
                right: -5%;
            }
        }

        @media (min-width: $lg) {
            display: block;
        }
    }
}