@use "../utils/variables" as *;

.cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    margin: 48px 0 16px 0;
    overflow-x: auto;
    width: 100%;
    $self: &;

    @media(min-width: 768px) {
        grid-column-gap: 30px;
    }

    &.store-area {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        margin: 48px auto 34px auto;
        justify-items: center;
        

        @media(min-width: 992px) {
            max-width: 900px;
        }

        @media(min-width: 1200px) {
            grid-column-gap: 40px;
        }
    }

    @media(min-width: 1200px) {
        overflow-x: visible;
        margin: 48px 0 25px 0;
    }

    &::-webkit-scrollbar {
        border-bottom: 5px solid #F4F4F4;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: $kdm-blue; 
        border-radius: 10px;
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
        position: relative;
        grid-row: 1;

        @media(min-width: 1200px) {
            grid-row: auto;
        }

        .card_image {
            border: 4px solid rgba(216, 213, 213, .5);
            padding: 20px 4px;
            position: relative;
            border-radius: 8px;
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media(min-width: 1200px) {
                width: 275px;
                height: 275px;
            }
            
            &.grey {
                background-color: rgba(216, 213, 213, .5);
                justify-content: center;

                >p {
                    font-size: 18px;
                }
            }

            img {
                max-height: 100%;
            }
    
            .check-box {
                position: absolute;
                top: 0;
                right: 0;
    
                &::after {
                    content: "";
                    border: 4px solid $kdm-card-grey;
                    background-color: $kdm-white;
                    width: 45px;
                    height: 45px;
                    position: absolute;
                    border-radius: 50%;
                    top: -15px;
                    right: -15px;
                }
    
                &.checked::after {
                    border: none;
                    content: url("../../../public/assets/icons/checkmark.svg");
                }

                .badges {
                    width: 45px;
                    height: 45px;
                    position: absolute;
                    top: -15px;
                    right: -15px;
                    color: $kdm-blue;
                    font-weight: 900;
                    font-size: 28px;
                    line-height: 33px;
                    text-align: center;
                    z-index: 9;
                    background-color: $kdm-blue--dark;
                    color: $kdm-white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    
        &__text {
            padding: 16px;
            text-align: center;
    
            p {
                font-size: 18px;
                line-height: 21px;
            }
        }
    }
}

