@use "../utils/variables"as *;

h1, .heading-1 {
    font-size: 32px;
    line-height: 110%;
    font-weight: 700;
    color: $kdm-blue;

    @media (min-width: $lg) {
        font-size: 64px;
        line-height: 71px;
    }
}

h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    margin-bottom: 18px;
}

span {
    &.highlight {
        $self: &;

        &__red {
            color: $kdm-red--error;
        }
    }
    
}

.text-center {
    text-align: center;
}