@use "../utils/variables"as *;
@use "sass:math";

.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    .loader {
        width: 90px;
        height: 90px;
        display: inline-block;
        animation: rotate-spinner 1.5s linear infinite;
        transform-origin: 50% 50%;
    }

    .loader-blade {
        position: absolute;
        width: 8px;
        height: 20px;
        border-radius: 20px;
        animation: spinning-loader 1s linear infinite;
        top: 32px;
        left: 42px;
        transition: all 1s ease;


        @for $i from 1 through 8 {
            &:nth-child(#{$i}) {
                transform: rotate(math.div(360deg, 8) * $i) translateY(-32px);
                animation-delay: math.div(1s, 8) * ($i - 18);
            }
        }

        &:first-child {
            height: 15px;
        }
    }    
}

@keyframes spinning-loader {
    0%, 100% {
        background-color: #C4D9FD;
    }
    30% {
        background-color: #73A1FB;
    }
    45% {
        background-color: #5286FA;
    }
    60% {
        background-color: #3D6DEB;
    }
    75% {
        background-color: #355EC9;
    }
    90% {
        background-color: $kdm-blue;      
    }
}

@keyframes rotate-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
