@use "../utils/variables"as *;

.modal {
    max-width: 465px;
    width: auto;
    position: absolute;
    top: 50%;
    left: 20px;
    right: 20px;
    transform: translate(0, -50%);
    padding: 15px;
    border: 1px solid $kdm-gray;
    background-color: $kdm-white;
    z-index: 9;
    
    @media (min-width: 475px) {
        width: 100%;
        right: auto;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 0;
        border-bottom: 1px solid #4D5256;
        margin-bottom: 30px;

        h2 {
            font-size: 24px;
            line-height: 20px;
            color: $kdm-black;
        }
    }

    &__content {
        margin-bottom: 60px;

        p {
            font-size: 20px;
            line-height: 20px;
            color: #171717;
            margin-bottom: 24px;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 15px;
        }
    }
}

.modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
}