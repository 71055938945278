//COLOURS

$kdm-white: #fff;
$kdm-black: #000;
$kdm-blue: #1796D3;
$kdm-blue--dark: #1F51FF;
$kdm-gray: #757575;
$kdm-gray--medium: #BBBBBB;
$kdm-gray--light: #F4F4F4;
$kdm-red--error: #fd2d47;
$kdm-circle--blue: #0073DA;
$kdm-card-grey: #D8D5D5;
$kdm-button-store--blue: #0089C2;
$kdm-button-store--grey: #EEEEEE;
$kdm-range-bg: #C4C4C4;


//FONTS

//BREAKPOINTS
$sm: 640px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$hd: 1800px;
$uw: 2200px;