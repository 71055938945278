@use "../utils/variables"as *;

.component-title {
    font-size: 22px;
    line-height: 26px;
    padding: 16px 30px;
    border-radius: 16px;
    background-color: $kdm-gray--light;
    font-weight: 400;
    text-align: center;
}