@use "../utils/variables"as *;

.form-container {
    margin: 0 auto;
    max-width: 614px;
    text-align: center;
    padding-bottom: 40px;

    .form-desc {
        padding-bottom: 15px;
        
        @media(min-width: $lg) {
            padding-bottom: 20px;
        }
    }

    form {
        margin-bottom: 30px;

        .input-container {
            text-align: left;
            width: 100%;

            input {
                width: 100%;
                margin-bottom: 10px;

                &.error {
                    border: 2px solid $kdm-red--error;
                }
            }
    
            small {
                color: $kdm-red--error;
                margin-top: -5px;
                display: block;
                margin-bottom: 10px;
            }
        }
    
        .input_wrapper {
            display: flex;
            flex-direction: column;
            column-gap: 10px;

            @media(min-width: 540px) {
                flex-direction: row;
            }
        }
    }
}