@use "../utils/variables"as *;

.button {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    padding: 10px 20px;
    min-width: 120px;
    color: $kdm-white;
    background-color: $kdm-blue;
    border-radius: 6px;
    border: 1px solid $kdm-blue;
    transition: background-color, color, border-color, 0.2s ease-in;

    &:hover {
        color: $kdm-blue;
        background-color: $kdm-white;
    }
    
    &.disable {
        background-color: $kdm-gray--medium;
        border-color: $kdm-gray--medium;

        &:hover {
            color: $kdm-white;
        }
    }

    &.grey {
        background-color: $kdm-gray--medium;
        border-color: $kdm-gray--medium;

        &:hover {
            color: $kdm-gray--medium;
            background-color: $kdm-white;
        }
    }

    &.transparent {
        background-color: $kdm-white;
        color: $kdm-blue;
        border-color: transparent;

        &:hover {
            color: $kdm-white;
            background-color: $kdm-blue;
        }
    }

    &.border {
        border-color: $kdm-blue;
    }

    &.store-size {
        flex: 1;
        color: $kdm-blue;
        background-color: $kdm-button-store--grey;
        padding: 20px 30px;
        border-radius: 60px;
        min-width: 270px;
        width: 100%;
        border: 1px solid transparent;
        font-size: 24px;
        line-height: 28px;

        &.checked {
            background-color: $kdm-blue;
            color: $kdm-button-store--grey;

            &:hover {
                border-color: $kdm-blue;
                background-color: $kdm-blue;
                color: $kdm-white;
            }
        }

        &:hover {
            border-color: $kdm-blue;
            background-color: $kdm-white;
        }
    }

    &.wide {
        color: $kdm-white;
        background-color: #02a665;
        border-color: #02a665;
        text-transform: uppercase;
        border-radius: 6px;
        width: 100%;
        padding: 21px;
        margin-top: 30px;
        max-width: 878px;

        &:hover {
            color: #02a665;
            background-color: $kdm-white;
        }
    }
}

.store-size_buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 15px;
    column-gap: 20px;
    margin: 30px 0;
    max-width: 846px;
    

    @media(min-width: 375px) {
        margin: 23px 30px 40px 30px;
    }

    @media(min-width: 640px) {
        flex-direction: row;
        margin: 50px auto;
    }

    @media(min-width: 1200px) {
        margin: 96px auto 100px auto;
        max-width: 1200px;
    }
}

.button-container {
    $self: &;
    text-align: center;
    padding-bottom: 45px;

    @media(min-width: 1200px) {
        text-align: right;
    }
    
    .grey {
        margin-right: 24px;
    }

    &--planograms {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 35px;
        row-gap: 10px;

        a {
            text-decoration: none;
        }

        @media(min-width: 640px) {
            flex-direction: row;
            column-gap: 22px;
        }
    }
}