@use "../utils/variables"as *;

.range_container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    column-gap: 36px;
    margin-bottom: 49px;
    justify-content: center;
    align-items: center;

    @media(min-width: 992px) {
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 132px;
    }
    
    @media(min-width: 768px) {
        padding: 0 20px;
    }

    .text_container {
        margin-bottom: 74px;
    }

    .range_wrapper {
        position: relative;
        width: 100%;

        .range-input_wrapper {
            

            input[type="range"] {
                width: 100%;
                -webkit-appearance: none;
                background: transparent;
                cursor: pointer;
                height: 17px;
                border-radius: 20px;
                background: $kdm-range-bg;
                background-image: linear-gradient($kdm-blue, $kdm-blue);
                background-repeat: no-repeat;
                
        
                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    width: 50px;
                    height: 50px;
                    background-color: $kdm-blue;
                    border-radius: 50%;
                    cursor: pointer;
                }

                &::-moz-range-thumb {
                    border: none;
                    -webkit-appearance: none;
                    width: 50px;
                    height: 50px;
                    background-color: $kdm-blue;
                    border-radius: 50%;
                    cursor: pointer;
                }
        
                &::-webkit-slider-runnable-track,
                &::-moz-range-track {
                    border-radius: 20px;
                    height: 17px;
                }
               
        
                &:focus {
                    outline: none;
                }
            }
    
            .range__labels {
                display: flex;
                white-space: nowrap;
                margin-top: 15px;
                
                @media(min-width: 992px) {
                    margin-top: 32px;
                }
        
                p {
                    flex: 1;
                    color: black;
                    font-size: 12px;

                    @media(min-width: 768px) {
                        font-size: 20px;
                    }

                    &:nth-child(2) {
                        text-align: center;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
        
        
        
    }

    .turnover {
        background-color: $kdm-gray--light;
        min-width: 164px;
        height: 40px;
        text-align: center;
        border-radius: 20px;
        white-space: nowrap;
        color: black;
        margin-top: 16px;
        position: relative;

        .pound {
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translateY(-50%);
        }

        &__input {
            background-color: transparent;
            border: none;
            width: 100px;
            opacity: 0;
            position: absolute;
            left: 40px;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 700;

            &:focus, &:focus-visible {
                outline: none;
                opacity: 1;
            }
        }

        strong {
            position: absolute;
            left: 40px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 1;

            &.active {
                opacity: 0;
            }
        }

        @media(min-width: 992px) {
            margin-top: -11px;
        }
    }
}

.turnover-text {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 90px;
    font-weight: 500;
}



