@use "../utils/variables"as *;

.header {
    padding-bottom: 12px;
    position: relative;
    margin-bottom: 20px;

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        &.mobile {
            @media (min-width: $md) {
                display: none;
            }
        }

        &.desktop {
            display: none;

            @media (min-width: $md) {
                display: block;
            }
        }
    }

    &__logo {
        .logo {
            width: 160px;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 9;
            
            @media (min-width: 375px) {
                width: 230px;
            }

            @media (min-width: $md) {
                top: 25px;
                left: 25px;
                width: 260px;
            }

            @media (min-width: $lg) {
                width: 314px;
            }
        }

        .logo-bg {
            width: 80%;
            height: 100%;
    
            @media (min-width: 475px) {
                max-width: 360px;
                width: 100%;
            }

            @media (min-width: $md) {
                max-width: 400px;
            }
    
            @media (min-width: $lg) {
                max-width: 470px;
            }
        }
    }

    .button {
        position: absolute;
        right: 15px;
        top: 15px;
        color: #1796D3;
        font-weight: 500;
        
        @media (min-width: $lg) {
            right: 30px;
            top: 30px;
        }
    }

    @media (min-width: $md) {
        padding-bottom: 24px;
    }

    @media (min-width: $lg) {
        margin-bottom: 50px;
    }
}