@use "../utils/variables"as *;

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    overflow-x: hidden;
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: $kdm-gray;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

img,
svg,
video {
    display: block;
    max-width: 100%;
    height: auto;
}

button {
    border: none;
    background-color: transparent;
    padding: 0;
    appearance: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.stuffs {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 9;
    background-color: $kdm-blue;
    color: white;
    font-size: 20px;
    padding: 10px;
    opacity: 0.2;

    &:hover {
        opacity: 1;
    }
}

.container {
    max-width: 1230px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    .no-padding {
        padding: 0;
    }
}

.selected {
    background-color: red;
}

.text-center {
    text-align: center;

    button {
        &.wide {
            margin-bottom: 60px;

            @media (min-width: $lg) {
                margin-bottom: 40px;
            }
        }
    }
}

.zero-search-response {
    text-align: center;
    padding: 20px 0;

    h4 {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 700;
        color: $kdm-blue;
        
        @media (min-width: $lg) {
            font-size: 24px;
        }
    }

    @media (min-width: $lg) {
        padding: 50px 0;
    }
}

.turnover-error-msg {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
    color: $kdm-red--error;
    position: absolute;
    left: 50%;
    top: -40px;
    transform: translateX(-50%);
}